import { IoMdClose } from '@react-icons/all-files/io/IoMdClose'
import {
  Button,
  Heading,
  HintText,
  Paragraph,
  Radio,
  WarningText
} from 'govuk-react'
import React, { ChangeEvent } from 'react'
import Modal from 'react-modal'
import styles from './CompleteBundlePopUp.module.scss'

export interface CompleteBundlePopUpProps {
  /**
   * Dynamic hint to render in pop up
   */

  /**
   * Set confirmation pop up to be open(true) or closed(false)
   */
  isOpen: boolean
  /**
   * Function invoked when pop up is closed
   */
  onCloseModal: () => void
  /**
   * Function invoked when the confirmation button is clicked
   */
  onConfirmationButtonClick: () => void
  /**
   * Label for the radion button if displayed
   */
  bundleExpiresAt: string

  radioButtonLabel: string | null
  /**
   * Hint for the radio button if displayed
   */
  radioButtonHint: string | null
  /**
   * Function invoked when the toggle is switched
   */
  onRadioButtonChange: (e: ChangeEvent<HTMLInputElement>) => void
}

/** A popup window implemented with the npm package react-modal which prompts the user to confirm their decision.*/
export const CompleteBundlePopUp: React.FunctionComponent<
  CompleteBundlePopUpProps
> = ({
  radioButtonHint,
  isOpen,
  onCloseModal,
  onConfirmationButtonClick,
  radioButtonLabel,
  onRadioButtonChange,
  bundleExpiresAt
}) => {
  const radioOptions = [
    {
      label: 'Yes',
      value: 'Yes'
    },
    {
      label: 'No',
      value: 'No'
    }
  ]

  return (
    <Modal
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div role={'region'} aria-labelledby={'modal-content'}>
        <div id={'modal-content'}>
          <button
            title="Close dialogue"
            onClick={onCloseModal}
            className={styles.closeBtn}
          >
            <IoMdClose />
          </button>
          <div className={styles.popUpTitle}>
            <Heading size="L">Complete this bundle</Heading>
          </div>
          <>
            <Paragraph>
              You will not be able to make further changes. Choose ‘Cancel’ if
              you’re not finished.
            </Paragraph>
          </>
          <div className={styles.warningMessage}>
            <WarningText>{`This bundle must be completed by ${bundleExpiresAt} or it will be deleted.`}</WarningText>
          </div>
          {radioButtonLabel && (
            <div>
              <Heading size="M">{radioButtonLabel}</Heading>
              <HintText>{radioButtonHint}</HintText>
              <div className={styles.uploadDocumentDiv}>
                {radioOptions?.map((option, id) => {
                  return (
                    <Radio
                      inline
                      name="uploadDocumentToDrs"
                      value={option.value}
                      key={id}
                      onChange={onRadioButtonChange}
                    >
                      {option.label}
                    </Radio>
                  )
                })}
              </div>
            </div>
          )}

          <div className={styles.popUpButtonDiv}>
            <Button
              margin={2}
              type="button"
              onClick={onConfirmationButtonClick}
            >
              Complete bundle
            </Button>
            <Button
              margin={2}
              buttonShadowColour="#A9A9A9"
              buttonTextColour="#0b0c0c"
              buttonHoverColour="#ffdd00"
              buttonColour="#f3f2f1"
              onClick={onCloseModal}
              name="Cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
